import styled from 'styled-components';

export const List = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
`;

export const Item = styled.li`
  margin: 0 1rem;
`;
