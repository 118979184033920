import React, { FC } from 'react';
import { UseFormMethods } from 'react-hook-form';

import { Wrapper, Input, InputControl, Label } from './styles';

type Props = {
  label: string;
  name: string;
  checked?: boolean;
  value: string;
  className?: string;
} & Partial<Pick<UseFormMethods, 'register'>>;

const RadioInput: FC<Props> = ({
  label,
  name,
  value,
  checked = false,
  register,
  className,
}) => (
  <Wrapper className={className}>
    <Input
      type="radio"
      name={name}
      value={value}
      ref={register}
      defaultChecked={checked}
    />
    <InputControl />
    <Label>{label}</Label>
  </Wrapper>
);

export default RadioInput;
