import React, { FC } from 'react';

import Link from 'components/Link';
import { Wrapper, Icon } from './styles';

type Props = {
  to: string;
  img: string;
  title?: string;
  alt?: string;
  className?: string;
};

const SocialBtn: FC<Props> = ({ to, img, title, alt, className }) => (
  <Wrapper as={Link} to={to} title={title} className={className}>
    <Icon src={img} alt={alt} draggable="false" />
  </Wrapper>
);

export default SocialBtn;
