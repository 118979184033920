import styled from 'styled-components';

export const Wrapper = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  background: ${({ theme }) => theme.color.dark};
`;

interface IsLargeNavProps {
  readonly isLargeNav: boolean;
}

export const Nav = styled.nav<IsLargeNavProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  max-width: 1316px;
  margin: 0 auto;
  padding: ${({ isLargeNav }) => (isLargeNav ? '3.6rem' : '2rem')} 2rem;
  transition: padding 0.15s ease-in-out;
  background-color: ${({ theme }) => theme.color.dark};
`;

export const StyledLogo = styled.div<IsLargeNavProps>`
  height: 3rem;
  transition: height 0.15s ease-in-out;

  ${({ theme }) => theme.mq.mobile} {
    height: ${({ isLargeNav }) => (isLargeNav ? '4.3rem' : '3.7rem')};
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-right: 1.5rem;

    ${({ theme }) => theme.mq.mobile} {
      margin-right: 1rem;
    }

    ${({ theme }) => theme.mq.desktop} {
      :last-child {
        margin: 0;
      }
    }

    ${({ theme }) => theme.mq.desktopLarge} {
      margin-right: 4rem;
    }
  }
`;

export const StyledMenuDesktop = styled.ul`
  display: none;

  ${({ theme }) => theme.mq.desktop} {
    display: flex;
    margin: 0 1rem;

    li {
      margin: 0 1rem;
    }
  }

  ${({ theme }) => theme.mq.desktopLarge} {
    margin-right: 6.6rem;

    li {
      margin: 0 2rem;
    }
  }
`;

export const StyledContactBtn = styled.button`
  display: none;
  ${({ theme }) => theme.mq.tablet} {
    display: block;
  }
`;

export const StyledMenuMobile = styled.ul`
  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

export const StyledBurger = styled.div`
  position: relative;
  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;
