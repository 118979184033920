import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 3.7rem;
`;

export const LogoImg = styled.img`
  display: block;
  height: 100%;
  transition: transform 0.15s ease-in;

  :active,
  :focus {
    transform: scale(0.99);
  }
`;
