import styled from 'styled-components';

export const Row = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DateRow = styled.fieldset`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 1.3rem;
  padding: 0;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  color: ${({ theme }) => theme.color.dark};
`;

export const DateLegend = styled.legend`
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

export const StyledInput = styled.div`
  flex: 1 0 200px;
  margin: 0 12px 12px 0;
`;

export const Submit = styled.section`
  align-self: flex-end;
  margin: 0 auto 2.4rem;
`;

export const StyledLink = styled.a`
  transition: color 0.2s ease-in-out;

  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const Credential = styled.label`
  flex: 1 0 250px;
  margin-right: 2.2rem;
  line-height: 1.8rem;
`;

export const Rodo = styled.footer`
  color: ${({ theme }) => theme.color.light};
  font-size: 1.1rem;
  line-height: 1.8rem;
`;
