import React, { FC } from 'react';

import Link from 'components/Link';
import { List, MenuHeader, MenuLink } from './styles';

export interface MenuItem {
  title: string;
  to: string;
  header?: boolean;
}

type Props = {
  items: MenuItem[];
  className?: string;
};

export const MenuFooter: FC<Props> = ({ items, className }) => (
  <List className={className}>
    {items.map(({ header, title, to }) =>
      header ? (
        <MenuHeader key={title}>{title}</MenuHeader>
      ) : (
        <li key={title}>
          <MenuLink as={Link} to={to}>
            {title}
          </MenuLink>
        </li>
      )
    )}
  </List>
);

export default MenuFooter;
