import styled from 'styled-components';

export const MenuLink = styled.a.attrs(({ theme }) => ({
  activeStyle: { color: theme.color.primary },
}))`
  transition: color 0.15s ease-in;
  outline: none;
  color: ${({ theme }) => theme.color.bright};
  font-size: 1em;
  font-weight: 600;
  line-height: 1.45;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  :hover,
  :focus {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export default MenuLink;
