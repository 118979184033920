import styled from 'styled-components';

export const Wrapper = styled.label`
  display: flex;
  position: relative;
  margin: 0 0 2.5rem;
  cursor: pointer;
`;

interface InputProps {
  readonly error?: boolean;
}

export const InputControl = styled.span<InputProps>`
  display: block;
  position: relative;
  min-width: 2rem;
  height: 2rem;
  margin-right: 1.2rem;
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.color.inputError : theme.color.inputBorder};
  border-radius: 3px;
  background: ${({ theme }) => theme.color.inputBg};

  ::before,
  ::after {
    content: '';
    position: absolute;
    transition: opacity 0.2s ease-in;
    opacity: 0;
  }

  ::before {
    width: 2rem;
    height: 2rem;
    margin: -1px;
    border-radius: 3px;
    background: ${({ theme }) => theme.color.primary};
  }

  ::after {
    top: 2px;
    left: 6px;
    width: 0.6rem;
    height: 1.2rem;
    transform: rotate(45deg);
    border: solid ${({ theme }) => theme.color.bright};
    border-width: 0 2px 2px 0;
  }

  :focus,
  :hover,
  :active {
    ::before {
      opacity: 0.3;
    }
  }
`;

export const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  :checked ~ ${InputControl}::before, :checked ~ ${InputControl}::after {
    opacity: 1;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.2rem;

  a {
    color: inherit;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -20px;
  width: 100%;
  margin: 0.5rem 0 0;
  color: ${({ theme }) => theme.color.inputError};
  font-size: 1.3rem;

  ::before {
    content: '⚠ ';
    display: inline;
  }
`;
