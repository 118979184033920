import styled from 'styled-components';

export const Wrapper = styled.button`
  display: inline-block;
  min-width: 133px;
  padding: ${({ type }) => (type ? '0.8rem' : '1.4rem')} 2rem;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border: none;
  border-radius: 30px;
  outline: none;
  background: ${({ theme }) => theme.color.primary};
  box-shadow: 0 10px 20px #11433d26;
  color: ${({ theme }) => theme.color.bright};
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  :active {
    transform: scale(0.95);
  }

  :hover,
  :focus,
  :active {
    background: ${({ theme }) => theme.color.primaryDark};
  }
`;
