import React, { FC, useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import Logo from 'components/Logo';
import MenuMobile from 'components/Menu/Mobile';
import MenuDesktop from 'components/Menu/Desktop';
import Burger from 'components/Button/Burger';
import ContactBtn from 'components/Button/Contact';
import SearchBtn from 'components/Button/Search';
import PhoneBtn from 'components/Button/Phone';
import SearchBar from 'components/SearchBar';
import {
  Wrapper,
  Nav,
  StyledLogo,
  MenuWrapper,
  StyledMenuDesktop,
  StyledMenuMobile,
  StyledContactBtn,
  StyledBurger,
} from './styles';

const data = graphql`
  {
    site {
      siteMetadata {
        contact {
          phone {
            title
            to
          }
        }
        menu {
          title
          to
        }
      }
    }
    logoIcon: file(relativePath: { eq: "icons/logo.svg" }) {
      publicURL
    }
  }
`;

type Props = {
  id: string;
};

const NavbarView: FC<Props> = ({ id }) => {
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isLargeNav, setLargeNav] = useState(pathname === '/');

  const [searchQuery, setSearchQuery] = useState('');
  const navbar = useRef<HTMLElement>(null);
  const searchInput = useRef<HTMLInputElement>(null);

  const { logoIcon, site } = useStaticQuery(data);
  const { menu, contact } = site.siteMetadata;

  useEffect(() => {
    const heightOnScroll = () => {
      if (pathname === '/') {
        window.pageYOffset === 0 ? setLargeNav(true) : setLargeNav(false);
      } else {
        setLargeNav(false);
      }
    };

    heightOnScroll();
    window.addEventListener('scroll', heightOnScroll);
    return () => {
      window.removeEventListener('scroll', heightOnScroll);
    };
  }, [pathname]);

  const disableBurger = () => setIsOpen(false);
  const toggleBurger = () => setIsOpen((state) => !state);

  const disableSearch = () => {
    searchInput?.current?.blur();
    setSearchQuery('');
    setSearchOpen(false);
  };

  const toggleSearch = () => {
    isSearchOpen ? searchInput?.current?.blur() : searchInput?.current?.focus();
    setSearchQuery('');

    setSearchOpen((state) => !state);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (navbar.current && !navbar.current.contains(event.target as Node)) {
        disableSearch();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [navbar]);

  useEffect(() => {
    const closeSearchBar = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc') setSearchQuery('');
    };

    window.addEventListener('keydown', closeSearchBar, true);
    return () => window.removeEventListener('keydown', closeSearchBar);
  }, []);

  return (
    <Wrapper id={id} ref={navbar}>
      <Nav isLargeNav={isLargeNav}>
        <StyledLogo
          as={Logo}
          to={'/'}
          alt="Simple Finance"
          image={logoIcon.publicURL}
          isLargeNav={isLargeNav}
        />

        <MenuWrapper>
          <StyledMenuDesktop as={MenuDesktop} items={menu} />
          <StyledContactBtn
            as={ContactBtn}
            title="Kontakt z ekspertem"
            to="/#contact"
          />
          <SearchBtn isActive={isSearchOpen} click={toggleSearch} />
          <PhoneBtn
            to={contact.phone.to}
            title={contact.phone.title}
            alt={`zadzwoń na ${contact.phone.to}`}
          />
        </MenuWrapper>

        <StyledMenuMobile
          as={MenuMobile}
          items={menu}
          click={disableBurger}
          isOpen={isOpen}
        />
        <StyledBurger as={Burger} click={toggleBurger} isOpen={isOpen} />
      </Nav>
      <SearchBar
        isActive={isSearchOpen}
        click={disableSearch}
        reference={searchInput}
        query={searchQuery}
        setQuery={setSearchQuery}
      />
    </Wrapper>
  );
};

export default NavbarView;
