import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import regex from 'utils/regex';

import Link from 'components/Link';
import RadioInput from 'components/Form/RadioInput';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';
import ActionBtn from 'components/Button/Action';
import {
  Row,
  StyledInput,
  DateRow,
  DateLegend,
  StyledLink,
  Credential,
  Submit,
  Rodo,
} from './styles';

interface FormData {
  type: string;
  name: string;
  phone: number;
  email: string;
  date: string;
  credential: boolean;
}

const defaultValues = {
  type: '0',
  name: '',
  phone: null,
  email: '',
  date: '0',
  credential: false,
};

type Props = {
  submit: (data: FormData) => void;
  className?: string;
};

const ContactForm: FC<Props> = ({ submit, className }) => {
  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues,
  });

  const { type, date, credential } = watch(['type', 'date', 'credential']);

  const onSubmit = (data: FormData) => {
    submit(data);
    reset();
  };

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <RadioInput
          register={register}
          name="type"
          value="0"
          label="Rozmowa telefoniczna"
          checked={type === '0'}
        />
        <RadioInput
          register={register}
          name="type"
          value="1"
          label="Wideo rozmowa online"
          checked={type === '1'}
        />
      </Row>

      <Row>
        <StyledInput
          as={Input}
          label="*Imię, nazwisko lub nazwa firmy"
          name="name"
          errors={errors}
          register={register}
          rules={{
            required: {
              value: true,
              message: 'Proszę podać imię i nazwisko lub nazwę firmy',
            },
          }}
        />
      </Row>

      <Row>
        <StyledInput
          as={Input}
          label="*Nr telefonu"
          name="phone"
          type="number"
          placeholder="+48"
          inputMode="tel"
          errors={errors}
          register={register}
          rules={{
            required: {
              value: true,
              message: 'Numer telefonu jest wymagany',
            },
            maxLength: 12,
            pattern: {
              value: regex.phone,
              message: 'Podany numer jest nieprawidłowy',
            },
          }}
        />

        <StyledInput
          as={Input}
          label="*Adres e-mail"
          name="email"
          type="email"
          errors={errors}
          register={register}
          rules={{
            required: {
              value: true,
              message: 'Adres e-mail jest wymagany',
            },
          }}
        />
      </Row>

      <DateRow>
        <DateLegend>Preferowany termin rozmowy</DateLegend>
        <RadioInput
          register={register}
          name="date"
          value="0"
          label="dziś"
          checked={date === '0'}
        />
        <RadioInput
          register={register}
          name="date"
          value="1"
          label="jutro"
          checked={date === '1'}
        />
        <RadioInput
          register={register}
          name="date"
          value="2"
          label="pojutrze"
          checked={date === '2'}
        />
      </DateRow>

      <Row>
        <Credential
          as={Checkbox}
          name="credential"
          errors={errors}
          register={register}
          checked={credential}
          rules={{
            required: {
              value: true,
              message: 'Proszę zaznaczyć pole',
            },
          }}
        >
          Oświadczam, że wszystkie podane przeze mnie informacje są prawdziwe i
          kompletne oraz, że zapoznałem się z{' '}
          <StyledLink as={Link} to="/o-nas/polityka-prywatnosci">
            polityką prywatności
          </StyledLink>
        </Credential>
        <Submit as={ActionBtn} type="submit" title="Wyślij" />
      </Row>
      <Rodo>
        Administratorem danych osobowych przetwarzanych w związku z przesłaniem
        korespondencji za pomocą udostępnionego formularza kontaktowego jest
        Spółka Simple Finance Sp. z o.o. zwana dalej Spółką, z siedzibą w
        Warszawie (00-640) przy ul. Mokotowska 1.
      </Rodo>
    </form>
  );
};

export default ContactForm;
