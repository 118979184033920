import React, { FC } from 'react';
import { Wrapper, Lines } from './styles';

type Props = {
  isOpen: boolean;
  click: () => void;
  className?: string;
};

const BurgerBtn: FC<Props> = ({ isOpen, click, className }) => (
  <Wrapper
    onClick={click}
    className={className}
    title="Nawigacja strony"
    type="button"
  >
    <Lines isOpen={isOpen} />
  </Wrapper>
);

export default BurgerBtn;
