import React, { FC } from 'react';

import Link from 'components/Link';
import { Wrapper, Icon } from './styles';

type Props = {
  to: string;
  title?: string;
  alt?: string;
  className?: string;
};

const PhoneBtn: FC<Props> = ({ to, title, alt, className }) => (
  <Wrapper as={Link} to={`tel:${to}`} title={title} className={className}>
    <Icon src="/icons/phone.svg" alt={alt} draggable="false" />
  </Wrapper>
);

export default PhoneBtn;
