import React, { FC, FormEvent } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';

import {
  Wrapper,
  Content,
  Input,
  Results,
  Item,
  StyledLink,
  Title,
  Text,
} from './styles';

interface DataQuery {
  localSearchPages: {
    index: string;
    store: {
      [id: string]: {
        id: string;
        slug: string;
        title: string;
        excerpt: string;
      };
    };
  };
}

const data = graphql`
  {
    localSearchPages {
      store
      index
    }
  }
`;

type Props = {
  isActive: boolean;
  click: () => void;
  query: string;
  setQuery: (input: string) => void;
  reference: React.RefObject<HTMLInputElement>;
  className?: string;
};

const SearchBar: FC<Props> = ({
  isActive,
  click,
  query,
  setQuery,
  reference,
  className,
}) => {
  const { localSearchPages } = useStaticQuery<DataQuery>(data);
  const { store, index } = localSearchPages;
  const results = useFlexSearch(query, index, store);

  const handleChange = (e: FormEvent<HTMLInputElement>) =>
    setQuery(e.currentTarget.value);

  return (
    <Wrapper active={isActive} className={className}>
      <Content>
        <Input
          ref={reference}
          placeholder="szukaj..."
          value={query}
          onChange={handleChange}
        />
        <Results>
          {results.map(({ id, slug, title, excerpt }) => (
            <Item key={id}>
              <StyledLink onClick={click} as={Link} to={slug}>
                <Title>{title}</Title>
                <Text>{excerpt}</Text>
              </StyledLink>
            </Item>
          ))}
        </Results>
      </Content>
    </Wrapper>
  );
};

export default SearchBar;
