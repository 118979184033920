import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  color: {
    bright: '#FFF',
    dark: '#063062',
    primary: '#2BE2BD',
    primaryDark: '#1dd4af',
    footer: '#FAFCFF',
    light: '#AABCD5',
    inputBg: '#FAFAFC',
    inputBorder: '#E6E6E6',
    inputColor: '#99abc1',
    inputError: '#bf1650',
  },
  mq: {
    mobile: '@media (min-width: 480px)',
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 992px)',
    desktopLarge: '@media (min-width: 1200px)',
  },
};

export default theme;
