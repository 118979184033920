// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kredyty-index-tsx": () => import("./../../../src/pages/kredyty/index.tsx" /* webpackChunkName: "component---src-pages-kredyty-index-tsx" */),
  "component---src-pages-leasing-i-faktoring-index-tsx": () => import("./../../../src/pages/leasing-i-faktoring/index.tsx" /* webpackChunkName: "component---src-pages-leasing-i-faktoring-index-tsx" */),
  "component---src-pages-o-nas-index-tsx": () => import("./../../../src/pages/o-nas/index.tsx" /* webpackChunkName: "component---src-pages-o-nas-index-tsx" */),
  "component---src-pages-pozyczki-index-tsx": () => import("./../../../src/pages/pozyczki/index.tsx" /* webpackChunkName: "component---src-pages-pozyczki-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-offer-index-tsx": () => import("./../../../src/templates/Offer/index.tsx" /* webpackChunkName: "component---src-templates-offer-index-tsx" */),
  "component---src-templates-privacy-index-tsx": () => import("./../../../src/templates/Privacy/index.tsx" /* webpackChunkName: "component---src-templates-privacy-index-tsx" */)
}

