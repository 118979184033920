import styled from 'styled-components';

interface SearchProps {
  readonly active: boolean;
}

export const Wrapper = styled.div<SearchProps>`
  display: flex;
  position: absolute;
  z-index: -1;
  top: 100%;
  right: 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateY(${({ active }) => (active ? '0' : '-300%')});
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: ${({ active }) => +active};
  background-color: ${({ theme }) => theme.color.dark};
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  width: 100%;
  max-width: 1316px;
  margin: 0 auto;
  padding: 1rem 2rem 2rem;
  ${({ theme }) => theme.mq.tablet} {
    padding: 2rem 2rem 3rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  max-width: 400px;
  height: 46px;
  padding: 0 2rem;
  transition: border-color 0.15s ease-in-out;
  border: 3px solid ${({ theme }) => theme.color.bright};
  border-radius: 50px;
  outline: none;
  background: ${({ theme }) => theme.color.inputBg};
  color: ${({ theme }) => theme.color.inputColor};
  font-size: 1.5rem;

  ::placeholder {
    color: inherit;
  }

  :focus {
    border: 3px solid ${({ theme }) => theme.color.primary};
  }
`;

export const Results = styled.ul`
  position: absolute;
  top: 100%;
  right: 0;
  max-width: 430px;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.color.dark};
  color: ${({ theme }) => theme.color.bright};
  list-style: none;

  ${({ theme }) => theme.mq.tablet} {
    max-height: 75vh;
  }
`;

export const Item = styled.li`
  margin: 1.5rem 2rem 3rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.bright};
`;

export const StyledLink = styled.a`
  display: inline-block;
  transition: color 0.15s ease-in-out;
  color: ${({ theme }) => theme.color.bright};
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export const Title = styled.h3`
  margin: 0 0 1rem;
  font-size: 1.5rem;
`;
export const Text = styled.p`
  margin: 0 0 1.5rem;
  font-size: 1.3rem;
  line-height: 2.4rem;
`;
