import styled from 'styled-components';

const lineWidth = '3.5rem';
const lineHeight = '4px';
const lineGap = '12px';
const transition = 'transform 0.25s ease-out';

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${lineWidth};
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

interface BurgerLinesProps {
  readonly isOpen: boolean;
}

export const Lines = styled.span<BurgerLinesProps>`
  position: relative;
  width: ${lineWidth};
  height: ${lineHeight};
  transform: ${({ isOpen }) => isOpen && 'rotate(225deg)'};
  transition: ${transition};
  border-radius: 50px;
  background: ${({ theme }) => theme.color.bright};
  cursor: pointer;

  ::after,
  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: ${lineHeight};
    transition: ${transition};
    border-radius: 50px;
    background: inherit;
  }

  ::before {
    transform: ${({ isOpen }) =>
      !isOpen ? `translatey(-${lineGap}) ` : 'rotate(90deg)'};
  }

  ::after {
    transform: ${({ isOpen }) =>
      !isOpen ? `translatey(${lineGap}) ` : 'translateY(0)'};
  }
`;
