import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormMethods, RegisterOptions } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Label, InputField, Error } from './styles';

type Props = {
  label: string;
  name: string;
  rules?: RegisterOptions;
} & Partial<Pick<UseFormMethods, 'register' | 'errors'>> &
  InputHTMLAttributes<HTMLInputElement>;

const Input: FC<Props> = ({
  label,
  name,
  register,
  errors = {},
  rules = {},
  type = 'text',
  placeholder,
  inputMode,
  className,
}) => (
  <label className={className}>
    <Label>{label}</Label>
    <InputField
      ref={register && register(rules)}
      type={type}
      name={name}
      placeholder={placeholder}
      inputMode={inputMode}
    />
    <ErrorMessage as={Error} errors={errors} name={name} />
  </label>
);

export default Input;
