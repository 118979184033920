import styled from 'styled-components';

interface ListProps {
  isOpen: boolean;
}

export const List = styled.ul<ListProps>`
  display: flex;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 10rem 5rem;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  background: ${({ theme }) => theme.color.dark};
  list-style: none;
  will-change: opacity;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 2rem 0;
  color: ${({ theme }) => theme.color.bright};
  font-size: 1em;
  font-weight: 600;
`;
