import styled from 'styled-components';

export const Wrapper = styled.a`
  display: inline-block;
  padding: 1.1rem;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  border-radius: 50%;
  outline: none;
  background-color: ${({ theme }) => theme.color.primary};

  :active {
    transform: scale(0.95);
  }

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.color.primaryDark};
  }
`;

export const Icon = styled.img`
  display: block;
  width: 1.7rem;
`;
