import styled from 'styled-components';

export const Wrapper = styled.button`
  display: inline-block;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 3.9rem;
  height: 3.9rem;
  overflow: hidden;
  transition: transform 0.15s ease-in-out;
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.color.dark};
  cursor: pointer;
`;

interface Active {
  readonly active: boolean;
}

export const Magnifier = styled.img<Active>`
  display: block;
  position: absolute;
  transform: translateY(${({ active }) => (active ? '50' : '0')}px);
  transition: transform 0.3s ease-in-out;
`;

export const Close = styled.span<Active>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateY(${({ active }) => (active ? '0' : '50')}px);
  transition: transform 0.3s ease-in-out;

  ::after,
  ::before {
    content: '';
    position: absolute;
    width: 2rem;
    height: 2px;
    transform: rotate(-45deg);
    border-radius: 2px;
    background-color: ${({ theme }) => theme.color.bright};
  }

  ::before {
    transform: rotate(-135deg);
  }
`;
