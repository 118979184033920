import styled from 'styled-components';

export const Label = styled.span`
  display: block;
  width: 100%;
  margin: 0 5px 5px;
  color: ${({ theme }) => theme.color.dark};
  font-family: Poppins, sans-serif;
  font-size: 1.3rem;
  line-height: 1.45;
`;

export const InputField = styled.input`
  width: 100%;
  height: 43px;
  padding: 0 2rem;
  transition: border-color 0.15s ease-in-out;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 50px;
  outline: none;
  background: ${({ theme }) => theme.color.inputBg};
  color: ${({ theme }) => theme.color.inputColor};
  font-size: 1.5rem;

  ::placeholder {
    color: inherit;
  }

  :focus {
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`;

export const Error = styled.span`
  margin: 0.5rem 0 0;
  color: ${({ theme }) => theme.color.inputError};
  font-size: 1.3rem;

  ::before {
    content: '⚠ ';
    display: inline;
  }
`;
