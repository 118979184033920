import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import phoneNumber from 'utils/formatter';

import Link from 'components/Link';
import ContactForm from 'components/Form/Contact';
import {
  Wrapper,
  Content,
  Title,
  Subtitle,
  Column,
  Text,
  Phone,
  Mail,
} from './styles';

const data = graphql`
  {
    site {
      siteMetadata {
        contact {
          mail
          phone {
            to
          }
        }
      }
    }
  }
`;

interface FormData {
  phone: number;
}

const ContactView: FC = () => {
  const { site } = useStaticQuery(data);
  const { mail, phone } = site.siteMetadata.contact;

  const handlePhoneSubmit = (data: FormData) => data;
  return (
    <Wrapper>
      <Content>
        <Title>Skontaktuj się z nami</Title>
        <Column id="contactForm">
          <Subtitle>Umów rozmowę z ekspertem</Subtitle>
          <ContactForm submit={handlePhoneSubmit} />
        </Column>
        <Column id="contact">
          <Subtitle>Szybki kontakt</Subtitle>
          <Text>Zadzwoń do eksperta</Text>
          <Text phone>
            <Phone>{phoneNumber(phone.to)}</Phone>
            <span>(Pn - Pt 9:00 - 18:00)</span>
          </Text>
          <Text>lub napisz email</Text>
          <Mail as={Link} to={`mailto:${mail}`}>
            {mail}
          </Mail>
        </Column>
      </Content>
    </Wrapper>
  );
};

export default ContactView;
