import React, { FC, MouseEvent } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

type Props = {
  click?: () => void;
} & GatsbyLinkProps<Record<string, unknown>>;

const Link: FC<Props> = ({
  to = '',
  click,
  title,
  activeStyle,
  className,
  children,
}) => {
  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.blur();
    if (click) click();
  };

  const firstChar = to && to.slice(0, 1);
  if (firstChar === '/') {
    return (
      <GatsbyLink
        onClick={onClick}
        to={to}
        activeStyle={activeStyle}
        partiallyActive={true}
        className={className}
        title={title}
      >
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a
      onClick={onClick}
      href={to}
      className={className}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

export default Link;
