import styled from 'styled-components';
import Link from 'components/Menu/Link';

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const MenuHeader = styled.h3`
  margin: 0 0 0.75rem;
  color: ${({ theme }) => theme.color.dark};
  font-family: inherit;
  font-size: 1.7rem;
  font-weight: 600;
`;

export const MenuLink = styled(Link)`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.3rem;
  font-weight: 400;
`;
