import styled from 'styled-components';

export const Wrapper = styled.a`
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
  margin-right: 2.3rem;
  transition: transform 0.15s ease-in;
  outline: none;

  :focus,
  :hover {
    transform: scale(1.07);
  }

  :active {
    transform: scale(0.95);
  }
`;

export const Icon = styled.img`
  display: block;
  height: 100%;
`;
