import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import GlobalStyle from 'styles/global';
import Navbar from 'views/Navbar';
import Footer from 'views/Footer';
import Contact from 'views/Contact';

const Layout: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Navbar id="navbar" />
    <main>{children}</main>
    <Contact />
    <Footer id="footer" />
  </ThemeProvider>
);

export default Layout;
