import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0.6rem 2.5rem;
  transition: transform 0.15s ease-in, color 0.15s ease-in;
  border: 2px solid;
  border-radius: 50px;
  border-color: ${({ theme }) => theme.color.primary};
  outline: none;
  background-color: transparent;
  color: ${({ theme }) => theme.color.bright};
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.45;
  text-decoration: none;

  :hover,
  :focus {
    color: ${({ theme }) => theme.color.primary};
  }

  :active {
    transform: scale(0.99);
  }
`;

export default Wrapper;
