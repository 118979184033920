import React, { FC } from 'react';

import Social from 'components/Button/Social';
import { Wrapper } from './styles';

export interface Social {
  name: 'facebook' | 'linkedin' | 'youtube' | 'instagram' | 'twitter';
  to: string;
}

type Props = {
  socials: Social[];
  className?: string;
};

const Socials: FC<Props> = ({ socials, className }) => {
  const imgs = {
    facebook: '/icons/socials/fb.svg',
    linkedin: '/icons/socials/in.svg',
    youtube: '/icons/socials/yt.svg',
    instagram: '/icons/socials/ig.svg',
    twitter: '/icons/socials/tw.svg',
  };

  return (
    <Wrapper className={className}>
      {socials.map(
        ({ name, to }: Social) =>
          Object.prototype.hasOwnProperty.call(imgs, name) && (
            <Social key={name} img={imgs[name]} alt={name} to={to} />
          )
      )}
    </Wrapper>
  );
};

export default Socials;
