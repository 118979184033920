import styled from 'styled-components';

export const SectionWrapper = styled.section`
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 2rem;
  color: ${({ theme }) => theme.color.dark};
`;

export const ContentWrapper = styled.div`
  max-width: 1140px;
  margin-left: auto;
`;

export const Title = styled.h1`
  margin: 0;
  color: ${({ theme }) => theme.color.primary};
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
`;

export const Subtitle = styled.h2`
  margin: 0 0 3rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 3.4rem;
  font-weight: 600;
  text-align: center;
`;

export const ColorPrimary = styled.span`
  color: ${({ theme }) => theme.color.primary};
`;

export const SectionTitle = styled.h2`
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 3.4rem;
  font-weight: 600;
  line-height: 4.4rem;
`;

export const SectionSubtitle = styled.h3`
  margin: 0 0 0.6rem;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
`;
