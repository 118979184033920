import styled from 'styled-components';

export const Wrapper = styled.footer`
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.color.footer};
`;

export const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1150px;
  margin: 0 auto;
  padding: 5.2rem 1.3rem 0;
  border-bottom: 1px solid #e9eef4;
`;

export const StyledMenu = styled.ul`
  flex: 1;
  margin-right: 3rem;
  margin-bottom: 3.7rem;
`;

export const Contact = styled.ul`
  margin: 0;
  padding: 0 0 1rem;
  list-style: none;
`;

interface ContactItem {
  readonly light?: boolean;
}

export const ContactItem = styled.li<ContactItem>`
  opacity: ${({ light }) => (light ? 0.45 : 1)};
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.4rem;
`;

export const StyledSocials = styled.div`
  padding: 3.7rem 0;
`;

export const Copyright = styled.p`
  max-width: 1150px;
  margin: 0 auto;
  padding: 2.3rem 1.3rem 1.7rem;
  color: ${({ theme }) => theme.color.light};
  font-size: 1.3rem;
`;
