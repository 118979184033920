import styled from 'styled-components';
import {
  SectionWrapper,
  ContentWrapper,
  SectionTitle,
} from 'components/Common';

export const Wrapper = styled(SectionWrapper)`
  background: url('/icons/watermark.svg') right no-repeat;
`;

export const Content = styled(ContentWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Title = styled(SectionTitle)`
  width: 100%;
  margin: 0 0 0.8rem;
`;

export const Subtitle = styled.h3`
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.color.primary};
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.45;
`;

export const Column = styled.div`
  flex: 1 0 100%;
  max-width: 510px;
  margin: 0 0 5rem;

  ${({ theme }) => theme.mq.tablet} {
    flex: 1;
    margin-right: 2rem;
  }
`;

interface TextProps {
  readonly phone?: boolean;
}

export const Text = styled.p<TextProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0 0 ${({ phone }) => (phone ? '3rem' : '1rem')};
  font-size: 1.4rem;
`;

export const Phone = styled.span`
  margin-right: 1.6rem;
  font-size: 2.6rem;
  font-weight: 600;

  ::before {
    content: '+48 ';
    font-size: 1.6rem;
  }
`;

export const Mail = styled.a`
  transition: color 0.15s ease-in;
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.7rem;
  font-weight: 600;
  text-decoration: none;

  :hover,
  :focus,
  :active {
    color: ${({ theme }) => theme.color.primary};
  }
`;
