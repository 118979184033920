import React, { FC } from 'react';

import { Wrapper, Magnifier, Close } from './styles';

type Props = {
  isActive: boolean;
  click: () => void;
  className?: string;
};

const SearchBtn: FC<Props> = ({ click, isActive, className }) => (
  <Wrapper className={className} onClick={click}>
    <Magnifier
      active={isActive}
      src="/icons/magnifier.svg"
      alt="szukaj"
      draggable="false"
    />
    <Close active={isActive} />
  </Wrapper>
);

export default SearchBtn;
