/* eslint import/prefer-default-export:0 */
/* eslint react/jsx-filename-extension: 0 */
import React from 'react';
import Layout from './src/views/Layout';
import getOffset from './src/utils/scroll';

const wrapPageElement = ({ element }) => <Layout>{element}</Layout>;

const shouldUpdateScroll = (
  { routerProps: { location }, prevRouterProps },
  opts = {}
) => {
  const { offsetElement = '#navbar' } = opts;

  if (!!location.hash && !!prevRouterProps) {
    const destinationElement = document.querySelector(location.hash);
    if (!destinationElement) return false;

    const destination = destinationElement.getBoundingClientRect().top;
    const offset = getOffset(offsetElement);

    window.scrollBy({
      top: destination - offset,
      behavior: 'smooth',
    });
    return false;
  }
};

export { wrapPageElement, shouldUpdateScroll };
