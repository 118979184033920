import React, { FC } from 'react';
import Link from 'components/Link';
import { Wrapper, LogoImg } from './styles';

type Props = {
  to?: string;
  title?: string;
  alt?: string;
  image: string;
  className?: string;
};

const Logo: FC<Props> = ({
  to = '/',
  title = 'Powrót do strony głównej',
  alt,
  image,
  className,
}) => (
  <Wrapper as={Link} to={to} title={title} className={className}>
    <LogoImg src={image} alt={alt} draggable="false" />
  </Wrapper>
);

export default Logo;
