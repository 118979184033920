import styled from 'styled-components';

export const Wrapper = styled.label`
  display: flex;
  margin: 0 1.2rem 2rem 0;
  cursor: pointer;
`;

export const InputControl = styled.span`
  display: block;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 0.9rem;
  border: 1px solid ${({ theme }) => theme.color.inputBorder};
  border-radius: 50%;
  background: ${({ theme }) => theme.color.inputBg};

  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 1.8rem;
    height: 1.8rem;
    margin: -1px;
    transition: opacity 0.15s ease-in;
    border: 5px solid ${({ theme }) => theme.color.primary};
    border-radius: 50%;
    opacity: 0;
  }
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
`;

export const Input = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  :focus ~ ${InputControl}::before, :hover ~ ${InputControl}::before {
    opacity: 0.3;
  }

  :checked ~ ${InputControl}::after {
    opacity: 1;
  }

  :checked ~ ${Label} {
    font-weight: 600;
  }
`;
