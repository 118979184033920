import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FooterQuery } from './interfaces';

import MenuFooter from 'components/Menu/Footer';
import Socials from 'components/Socials';
import {
  Wrapper,
  Nav,
  StyledMenu,
  Contact,
  ContactItem,
  StyledSocials,
  Copyright,
} from './styles';

const data = graphql`
  {
    site {
      siteMetadata {
        footer {
          header
          title
          to
        }
        contact {
          address {
            city
            name
            street
          }
          company {
            KRS
            NIP
            REGON
          }
          socials {
            name
            to
          }
        }
      }
    }
  }
`;

type Props = {
  id: string;
};

const FooterView: FC<Props> = ({ id }) => {
  const { site } = useStaticQuery<FooterQuery>(data);
  const { footer, contact } = site.siteMetadata;
  const { address, company, socials } = contact;

  return (
    <Wrapper id={id}>
      <Nav>
        {footer.map((items, index) => (
          <StyledMenu as={MenuFooter} key={index} items={items} />
        ))}
        <div>
          <Contact>
            <ContactItem>{address.name}</ContactItem>
            <ContactItem>{address.street}</ContactItem>
            <ContactItem>{address.city}</ContactItem>
          </Contact>
          <Contact>
            <ContactItem light>KRS {company.KRS}</ContactItem>
            <ContactItem light>NIP {company.NIP}</ContactItem>
            <ContactItem light>REGON {company.REGON}</ContactItem>
          </Contact>
          <StyledSocials as={Socials} socials={socials} />
        </div>
      </Nav>
      <Copyright>(c) 2020 SimpleFinance.pl</Copyright>
    </Wrapper>
  );
};

export default FooterView;
