import React, { FC } from 'react';

import Link from 'components/Link';
import { Wrapper } from './styles';

type Props = {
  to?: string;
  type?: 'button' | 'submit' | 'reset';
  title?: string;
  className?: string;
};

const ActionBtn: FC<Props> = ({ to, type = 'button', title, className }) =>
  to ? (
    <Wrapper as={Link} to={to} title={title} className={className}>
      {title}
    </Wrapper>
  ) : (
    <Wrapper type={type} title={title} className={className}>
      {title}
    </Wrapper>
  );

export default ActionBtn;
