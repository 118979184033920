import React, { FC, InputHTMLAttributes } from 'react';
import { UseFormMethods, RegisterOptions } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { Wrapper, Input, InputControl, Label, Error } from './styles';

type Props = {
  name: string;
  rules?: RegisterOptions;
} & Partial<Pick<UseFormMethods, 'register' | 'errors'>> &
  InputHTMLAttributes<HTMLInputElement>;

const Checkbox: FC<Props> = ({
  name,
  register,
  checked = false,
  errors = {},
  rules = {},
  className,
  children,
}) => (
  <Wrapper className={className}>
    <Input
      ref={register && register(rules)}
      type="checkbox"
      name={name}
      defaultChecked={checked}
    />
    <InputControl error={errors[name]} />
    <Label>{children}</Label>
    <ErrorMessage as={Error} errors={errors} name={name} />
  </Wrapper>
);

export default Checkbox;
