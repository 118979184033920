import React, { FC } from 'react';
import Link from 'components/Link';
import Wrapper from './styles';

type Props = {
  to: string;
  title: string;
  className?: string;
};

const ContactBtn: FC<Props> = ({ to, title, className }) => (
  <Wrapper as={Link} to={to} className={className}>
    {title}
  </Wrapper>
);

export default ContactBtn;
