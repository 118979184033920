import React, { FC } from 'react';

import Link from 'components/Link';
import MenuLink from 'components/Menu/Link';
import { List, Item } from './styles';

interface MenuItem {
  title: string;
  to: string;
}

type Props = {
  items: MenuItem[];
  click?: () => void;
  className?: string;
};

const Menu: FC<Props> = ({ items, click, className }) => (
  <List className={className}>
    {items.map(({ title, to }) => (
      <Item key={title}>
        <MenuLink as={Link} to={to} click={click}>
          {title}
        </MenuLink>
      </Item>
    ))}
  </List>
);

export default Menu;
